<template>
	<el-dialog :visible.sync="centerDialogVisible" :before-close="close" width="70%" center>
		<el-dialog width="80%" title="院校介绍" :visible.sync="introduceShow" append-to-body>
			<div class="inner" v-if="introduce !== ''" v-html="introduce">...</div>
		</el-dialog>
		<el-dialog width="80%" title="招生简章" :visible.sync="recruitShow" append-to-body>
			<div class="inner" v-if="recruit !== ''" v-html="recruit">...</div>
		</el-dialog>
		<div class="head">
			<img :src="uniInfo.logo">
			<div class="content">
				<p class="schoolName">{{uniInfo.schoolName}}</p>
				<div class="tags">
					<el-tag type="primary" size="small" v-for="tag in uniInfo.diC_KeySchool" :key="tag">{{filter(tag)}}
					</el-tag>
				</div>
			</div>
		</div>
		<div class="content">

			<el-tabs v-model="activeName">
				<!-- @tab-click="handleClick" -->
				<el-tab-pane label="院校主页" name="index">
					<div class="uniIndex">
						<div class="introduce">
							<h3>院校介绍</h3>
							<div class="inner" v-if="introduce !== ''" v-html="introduce">...</div>
							
							<el-button type="primary" size="small" @click="introduceShow = true" style="float: right;">
								查看完整介绍</el-button>
						</div>
						<div class="baseInfo">
							<h3>基础信息</h3>
							<div class="inner">
								<div class="cell">
									<span class="title">创建时间</span>
									<span class="content">{{uniInfo.createYear}}</span>
								</div>
								<div class="cell">
									<span class="title">办学性质</span>
									<span
										class="content">{{getGategoryName(uniInfo.syS_CreateType,'syS_CreateType')}}</span>
								</div>
								<div class="cell">
									<span class="title">院校层次</span>
									<span class="content">{{getGategoryName(uniInfo.syS_Level,'syS_Level')}}</span>
								</div>
								<div class="cell">
									<span class="title">类型</span>
									<span
										class="content">{{getGategoryName(uniInfo.syS_SchoolType,'syS_SchoolType')}}</span>
								</div>
								<div class="cell">
									<span class="title">学校地址</span>
									<span class="content">{{getAreaName(uniInfo.province)}}</span>
								</div>
								<div class="cell">
									<span class="title">招生电话</span>
									<span class="content">{{uniInfo.uniInfo || '/'}}</span>
								</div>
								<div class="cell">
									<span class="title">学校官网</span>
									<span class="content">{{uniInfo.schooUrl}}</span>
								</div>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="院系设置" name="set">
					<!-- <el-table :data="faculty" border style="width: 100%;" v-loading="listLoading"
						:row-style="{height: '50px'}" height="calc(100% - 150px)">
						<el-table-column prop="name" label="学院" width="200px">
							<template slot-scope="scope">
								<span style="font-weight: bold;">{{scope.row.name}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="major" label="所含专业">
							<template slot-scope="scope">
								<span style="margin-right: 30px;" v-for="item in scope.row.major" :key="item">•
									&nbsp;{{item}}</span>
							</template>
						</el-table-column>
					</el-table> -->
					<div class="inner" v-if="deptSet !== ''" v-html="deptSet">...</div>
				</el-tab-pane>
				<el-tab-pane label="招生章程" name="con">
					<div class="recruit">
						<div class="inner" v-if="recruit !== ''" v-html="recruit">...</div>
						<el-button type="primary" size="small" @click="recruitShow = true" style="float: right;">
							查看完整介绍</el-button>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</el-dialog>
</template>

<script>
	//import * as schoolSubjects from '@/api/schoolsubjects'
	import * as schoolContents from '@/api/schoolcontents'
	import * as categorys from '@/api/categorys'
	//import * as subjects from "@/api/subjects";
	import {
		cityData
	} from '../../components/cityData.js'
	export default {
		data() {
			return {
				list: [],
				total: 0,
				listLoading: false,
				gategoryList: [],
				logo: '',
				centerDialogVisible: false,
				introduceShow: false,
				recruitShow: false,
				activeName: 'index',
				faculty: [{
					name: '基础教育学院-预科部',
					major: ['学前教育（本）', '少数民族预科（本）', '小学教育（本）']
				}],
				listQuery: { // 查询条件
					page: 1,
					limit: 20,
					key: undefined,
					appId: undefined,
					schoolCode: '', // 学校编号
					syS_SchoolContentType: '', // 内容类型
					contentText: '', // 内容

				},
				introduce: '',
				recruit: '',
				teacherPower:'',
				deptSet:'',
			};
		},
		props: {
			dialogShow: Boolean,
			uniInfo: Object,
			charOptions: Array
		},
		watch: {
			dialogShow(nv) {
				this.centerDialogVisible = nv;
			},
			uniInfo(nv) {
				this.listQuery.schoolCode = nv.schoolCode;
				this.getList();
				this.getGategorys();
			},
			list(nv) {
				nv.map(item => {
					if (item.syS_SchoolContentType == "Recruit") {
						this.recruit = item.contentText;
					}else if(item.syS_SchoolContentType == "Introduce") {
						this.introduce = item.contentText;
					}else if (item.syS_SchoolContentType == "DeptSet"){
						this.deptSet = item.contentText;
					}else{
						this.teacherPower = item.contentText;
					}
				})
			}
		},
		methods: {
			// handleClick(tab) {
			// 	if (tab.name == "set") {
			// 		let listQuery = {
			// 			page: 1,
			// 			limit: 20,
			// 			key: undefined,
			// 			appId: undefined,
			// 			syS_Years: '', // 年份
			// 			syS_SubjectGroup: '', // 文理科
			// 			subjectCode: '', // 专业代码
			// 			subjectName: '', // 专业名称
			// 			syS_Times: '', // 批次
			// 			minPlans: '', // 最小计划数
			// 			maxPlans: '', // 最大计划数
			// 			minPosts: '', // 最小投档数
			// 			maxPosts: '', // 最大投档数
			// 			minReceives: '', // 最小录取数
			// 			maxReceives: '', // 最大录取数
			// 			scores: '', // 录取详情
			// 			minMinscore: '', // 最小最低分
			// 			maxMinscore: '', // 最大最低分
			// 			minAvescore: '', // 最小平均分
			// 			maxAvescore: '', // 最大平均分
			// 			minScoreline: '', // 最小分数线
			// 			maxScoreline: '', // 最大分数线
			// 			minMinscoredif: '', // 最小最低分线差
			// 			maxMinscoredif: '', // 最大最低分线差
			// 			minAvescoredif: '', // 最小平均分线差
			// 			maxAvescoredif: '', // 最大平均分线差
			// 			minMinplace: '', // 最小最低分名次
			// 			maxMinplace: '', // 最大最低分名次
			// 			minScorelineplace: '', // 最小分数线名次
			// 			maxScorelineplace: '', // 最大分数线名次
			// 			minAveplace: '', // 最小平均分名次
			// 			maxAveplace: '', // 最大平均分名次
			// 			minSolicitation: '', // 最小征集数
			// 			maxSolicitation: '', // 最大征集数
			// 			minSchoolcode: '', // 最小学校代码
			// 			maxSchoolcode: '', // 最大学校代码
			// 		}
			// 		listQuery.minSchoolcode = this.listQuery.schoolCode;
			// 		listQuery.maxSchoolcode = this.listQuery.schoolCode;
			// 		schoolSubjects.getList(listQuery).then(response => {
			// 			console.log(response.data);
			// 		})
			// 	}
			// },
			close() {
				this.centerDialogVisible = false;
				this.$emit('closeUniinfo', false);
				setTimeout(() => {
					this.activeName = 'index';
					this.introduce = '';
					this.recruit = '';
				},500)
			},
			filter(val) {
				let item = this.charOptions.find(item => item.value == val);
				//console.log(item.label);
				if (item) {
					return item.label;
				} else {
					return '/'
				}
			},
			getList() {
				this.list = [];
				this.listLoading = true
				schoolContents.getList(this.listQuery).then(response => {
					//console.log(response);
					let list1 = response.data;
					for (let i = 0; i < list1.length; i++) {
						var o = list1[i]
						this.list.push(o);
					}
					console.log(this.list);
					//this.listLoading = true
					//schoolContents.getList(this.listQuery).then(response => {
					//  this.list = response.data
					this.total = response.count
					this.listLoading = false
				})
			},

			getGategorys() {
				let listQuery = {
					page: 1,
					limit: 9999
				};
				console.log('getcategorys')
				categorys.getList(listQuery).then(res => {
					this.gategoryList = res.data
					console.log('getcategorys2')
					//for(let key in this.gategoryList){
					//    console.log(this.gategoryList[key])
					//}
					console.log('getcategorys3')
				})
			},
			getGategoryName(val, columnName) {

				var dicobject = this.gategoryList.find(t => t.typeId.toUpperCase() == columnName.toUpperCase() && t
					.dtValue == val)
				//var dicobject = this.gategoryList.find(t => { 
				//  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) { 
				//    return t
				//  } 
				//});

				if (!dicobject) {
					return ''
				} else {
					return dicobject.name
				}
			},
			getAreaList(code) {
				let temp = [];
				if (typeof code === "undefined") {
					code = '111111';
				}
				if (code.substr(3, 3) == '000') { //城市列表

					for (let i in cityData[code]) {
						let o = {
							code: i,
							city: cityData[code][i]
						};
						temp.push(o);
					}
					//console.log(this.city);
				} else if (code.substr(4, 2) == "00") { //县区列表

					for (let i in cityData[code]) {
						let o = {
							code: i,
							county: cityData[code][i]
						};
						temp.push(o);
					}
					//console.log(this.county);
				} else { //省份列表
					for (let key in cityData.province) {
						cityData.province[key].map(province => {
							temp.push(province);
						})
					}
				}
				return temp;
			},
			// 通过地址码显示对应的名称
			getAreaName(val) {
				if (val) {
					if (val.substr(3, 3) == '000') { //省份名称
						for (let key in cityData.province) {
							//console.log(cityData.province[key]);
							for (let i = 0; i < cityData.province[key].length; i++) {
								if (cityData.province[key][i].code == val) {
									//console.log(cityData.province[key][i].address);
									return cityData.province[key][i].address;
								}
							}
						}
					} else if (val.substr(4, 2) == "00") { //城市名称
						let cityParentCode = val.substr(0, 3) + "000";
						for (let i in cityData[cityParentCode]) {
							if (i == val) {
								return cityData[cityParentCode][i]
							}
						}
					} else {
						let countyParentCode = val.substr(0, 4) + "00";
						for (let i in cityData[countyParentCode]) {
							if (i == val) {
								return cityData[countyParentCode][i]
							}
						}
					}
				}
				//console.log(val);
				return '';
			},
		}
	};
</script>

<style lang="scss" scoped="">
	.head {
		padding: 10px 0;
		display: flex;
		font-size: 16px;

		img {
			width: 20%;
			padding: 0 10px;
		}

		.content {
			padding: 15px 0;

			.schoolName {
				font-size: 36px;
				margin-bottom: 15px;
			}

			.tags {
				.el-tag {
					margin: 0 5px;
				}
			}
		}
	}

	.content {
		.uniIndex {
			display: flex;

			.introduce {
				width: 65%;
				margin-right: 5%;

				.inner {
					padding: 10px;
					height: 320px;
					overflow: hidden;
				}
			}

			.baseInfo {
				flex: 1;

				.inner {
					.cell {
						border-bottom: 1px solid #eee;
						padding: 10px 0;
						display: flex;
						font-size: 16px;

						.title {
							width: 30%;
						}

						.content {
							flex: 1;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: #bbb;

						}
					}

				}
			}
		}

		.recruit {
			.inner {
				padding: 10px;
				height: 320px;
				overflow: hidden;
			}
		}
	}
</style>
