<template>
	<div class="newScheme">
		<div class="top">
			<h3>知分方案设计</h3>
			<h3 class="screen">高级筛选</h3>
			<div class="school">
				共<span class="total">{{ total }}</span>所 &nbsp;&nbsp; 已选院校
				<span class="uniNum">{{ uniSelect.length }}</span>所 &nbsp;&nbsp; 已选专业 <span class="majorNum">0</span>个
			</div>
			<span class="set">设置</span>
		</div>
		<div class="container">
			<p>可选院校列表 【主年份】 &nbsp; {{ thisYear }}</p>
			<el-table ref="mainTable" :data="list" border style="width: 100%" :row-style="{ height: '50px' }"
				v-loading="listLoading" @select="handleSelect" @select-all="handleSelectAll" @row-dblclick="rowClick"
				:header-row-style="{ cursor: 'pointer' }" height="calc(100% - 75px)">
				<el-table-column type="selection" align="center"></el-table-column>
				<el-table-column prop="schoolName" label="院校名称" fixed width="150">
					<template slot="header">
						<el-popover placement="bottom" width="295" trigger="click"
							@hide="handlePopoverHide('schoolName')">
							<div class="selectBox">
								<el-input placeholder="请输入院校名称" v-model="listQuery.schoolName" size="mini"
									@keyup.enter="getList()"></el-input>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">院校名称<i class="el-icon-arrow-down"></i></span>
						</el-popover>
						<!-- <el-link :underline="false" type="primary" slot="reference">{{scope.row.name}}</el-link> -->
					</template>

					<template slot-scope="scope">
						<el-link :underline="false" type="primary" @click="UniInfoshow(scope.$index)">
							{{ scope.row.schoolName }}
						</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="myChance" label="录取率">
					<template slot-scope="scope">
						<span>{{scope.row.myChance}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="schoolCode" label="院校代码" width="100">
					<template slot="header">
						<el-popover placement="bottom" width="295" trigger="click"
							@hide="handlePopoverHide('schoolCode')">
							<div class="selectBox">
								<el-input placeholder="请输入院校代码" v-model="listQuery.schoolCode" size="mini"
									@keyup.enter="getList()"></el-input>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">院校代码<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="schoolCode" label="历年数据" width="100">
					<template slot-scope="scope">
						<el-button type="primary" icon="el-icon-search" circle size="mini"
							@click="searchHistory(scope.$index)"></el-button>
					</template>
				</el-table-column>
				<el-table-column label="文理科" v-if="false">
					<template slot="header">
						<el-popover placement="bottom" width="295" trigger="click"
							@hide="handlePopoverHide('syS_SubjectGroup')">
							<div class="selectBox">
								<el-select v-model="listQuery.syS_SubjectGroup" placeholder="请选择" size="mini">
									<el-option v-for="item in SubjectGroup" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">文理科<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
					<template slot-scope="scope">
						<span>{{
              getGategoryName(scope.row.syS_SubjectGroup, "SYS_SubjectGroup")
            }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="province" label="省份">
					<template slot="header">
						<el-popover placement="bottom" width="295" trigger="click"
							@hide="handlePopoverHide('province')">
							<div class="selectBox">
								<el-select v-model="listQuery.province" placeholder="请选择" size="mini">
									<el-option v-for="item in province" :key="item.code" :label="item.address"
										:value="item.code">
									</el-option>
								</el-select>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">省份<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
					<template slot-scope="scope">
						<span>{{ getAreaName(scope.row.province) }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="cityName" label="城市">
					<template slot="header">
						<el-popover placement="bottom" width="295" trigger="click"
							@hide="handlePopoverHide('cityName')">
							<div class="selectBox">
								<el-input placeholder="请输入城市" v-model="listQuery.cityName" size="mini"
									@keyup.enter="getList"></el-input>
								<el-button size="mini" style="margin-left: 10px" @click="getList">确认</el-button>
							</div>
							<span slot="reference">城市<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
					<template slot-scope="scope">
						<span>{{ scope.row.cityName }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="syS_CreateType" label="院校类别" width="100">
					<template slot="header">
						<el-popover placement="bottom" width="295" trigger="click"
							@hide="handlePopoverHide('syS_CreateType')">
							<div class="selectBox">
								<AuthSelect :placeholder="'请选择学校类型'" :isEdit="true" :data-source="'SYS_CreateType'"
									v-model="listQuery.syS_CreateType" size="small"></AuthSelect>
								<el-button size="mini" style="margin-left: 10px" @click="getList">确认</el-button>
							</div>
							<span slot="reference">院校类别<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
					<template slot-scope="scope">
						<span>
							{{ getGategoryName(scope.row.syS_CreateType, "SYS_CreateType") }}
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="diC_KeySchool" label="院校档次" width="100">
					<template slot="header">
						<el-popover placement="bottom" width="295" trigger="click"
							@hide="handlePopoverHide('diC_KeySchool')">
							<div class="selectBox">
								<AuthSelect :isEdit="true" :data-source="'DIC_KeySchool'"
									v-model="listQuery.diC_KeySchool" :placeholder="'院校档次'" class="searchChar"
									:size="'small'" :multiple="true" :collapseTags="true"></AuthSelect>
								<el-button size="mini" style="margin-left: 10px" @click="getList">确认</el-button>
							</div>
							<span slot="reference">院校档次<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
					<template slot-scope="scope">
						<span>
							<el-tag size="small" effect="light" v-for="item in scope.row.diC_KeySchool" :key="item">
								<!-- {{ getGategoryName(item, "DIC_KeySchool") }} -->
								{{item}}
							</el-tag>
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="syS_Times" label="批次" width="150">
					<template slot="header">
						<el-popover placement="bottom" width="295" trigger="click"
							@hide="handlePopoverHide('syS_Times')">
							<div class="selectBox">
								<!-- <el-select v-model="listQuery.syS_Times" placeholder="请选择" size="mini">
									<el-option v-for="item in syS_Times" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select> -->
								<AuthSelect :data-source="'SYS_Times'" v-model="listQuery.syS_Times" size="mini">
								</AuthSelect>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">批次<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
					<template slot-scope="scope">
						<span>{{ getGategoryName(scope.row.syS_Times, "SYS_Times") }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="scores" width="110">
					<template slot="header">
						<el-popover placement="bottom" width="250" trigger="click"
							@hide="handlePopoverHide('minScores', 'maxScores')">
							<div class="selectBox">
								<el-input placeholder="最小值" v-model="listQuery.minScores" size="mini"
									style="width: 70px"></el-input>&nbsp;-
								<el-input placeholder="最大值" v-model="listQuery.maxScores" size="mini"
									style="width: 70px"></el-input>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">分数详情<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="plans" width="110">
					<template slot="header">
						<el-popover placement="bottom" width="250" trigger="click"
							@hide="handlePopoverHide('minPlans', 'maxPlans')">
							<div class="selectBox">
								<el-input placeholder="最小值" v-model="listQuery.minPlans" size="mini"
									style="width: 70px"></el-input>&nbsp;-
								<el-input placeholder="最大值" v-model="listQuery.maxPlans" size="mini"
									style="width: 70px"></el-input>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">{{ thisYear }}计划数<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="minscore" width="110">
					<template slot="header">
						<el-popover placement="bottom" width="250" trigger="click"
							@hide="handlePopoverHide('minscore')">
							<div class="selectBox">
								<el-input v-model="listQuery.minscore" size="mini"></el-input>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">{{ thisYear }}最低分<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="avescore" width="110">
					<template slot="header">
						<el-popover placement="bottom" width="250" trigger="click"
							@hide="handlePopoverHide('avescore')">
							<div class="selectBox">
								<el-input v-model="listQuery.avescore" size="mini"></el-input>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">{{ thisYear }}平均分<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="scoreline" width="110">
					<template slot="header">
						<el-popover placement="bottom" width="250" trigger="click"
							@hide="handlePopoverHide('scoreline')">
							<div class="selectBox">
								<el-input v-model="listQuery.scoreline" size="mini"></el-input>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">{{ thisYear }}分数线<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="plansPostsReceives1" width="110" :label="oneYear + '计划数'"></el-table-column>
				<el-table-column prop="minScore1" width="110" :label="oneYear + '最低分'"></el-table-column>
				<el-table-column prop="aveScore1" width="110" :label="oneYear + '平均分'"></el-table-column>
				<el-table-column prop="scoreline1" width="110" :label="oneYear + '分数线'"></el-table-column>
				<el-table-column prop="plansPostsReceives2" width="110" :label="twoYear + '计划数'"></el-table-column>
				<el-table-column prop="minScore2" width="110" :label="twoYear + '最低分'"></el-table-column>
				<el-table-column prop="aveScore2" width="110" :label="twoYear + '平均分'"></el-table-column>
				<el-table-column prop="scoreline2" width="110" :label="twoYear + '分数线'"></el-table-column>
				<el-table-column prop="plansPostsReceives3" width="110" :label="threeYear + '计划数'"></el-table-column>
				<el-table-column prop="minScore3" width="110" :label="threeYear + '最低分'"></el-table-column>
				<el-table-column prop="aveScore3" width="110" :label="threeYear + '平均分'"></el-table-column>
				<el-table-column prop="scoreline3" width="110" :label="threeYear + '分数线'"></el-table-column>
				<el-table-column prop="posts" width="110">
					<template slot="header">
						<el-popover placement="bottom" width="250" trigger="click"
							@hide="handlePopoverHide('minPosts', 'maxPosts')">
							<div class="selectBox">
								<el-input placeholder="最小值" v-model="listQuery.minPosts" size="mini"
									style="width: 70px"></el-input>&nbsp;-
								<el-input placeholder="最大值" v-model="listQuery.maxPosts" size="mini"
									style="width: 70px"></el-input>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">投档数<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="receives" width="110">
					<template slot="header">
						<el-popover placement="bottom" width="250" trigger="click"
							@hide="handlePopoverHide('minReceives', 'maxReceives')">
							<div class="selectBox">
								<el-input placeholder="最小值" v-model="listQuery.minReceives" size="mini"
									style="width: 70px"></el-input>&nbsp;-
								<el-input placeholder="最大值" v-model="listQuery.maxReceives" size="mini"
									style="width: 70px"></el-input>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">录取数<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="minscoredif" width="110">
					<template slot="header">
						<el-popover placement="bottom" width="250" trigger="click"
							@hide="handlePopoverHide('minscoredif')">
							<div class="selectBox">
								<el-input v-model="listQuery.minscoredif" size="mini" style="width: 70px">
								</el-input>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">最低分线差<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="avescoredif" width="110">
					<template slot="header">
						<el-popover placement="bottom" width="250" trigger="click"
							@hide="handlePopoverHide('avescoredif')">
							<div class="selectBox">
								<el-input v-model="listQuery.avescoredif" size="mini" style="width: 70px">
								</el-input>
								<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
							</div>
							<span slot="reference">平均分线差<i class="el-icon-arrow-down"></i></span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="minplace" label="最低分名次" show-overflow-tooltip></el-table-column>
				<el-table-column prop="scorelineplace" label="分数线名次" show-overflow-tooltip></el-table-column>
				<el-table-column prop="aveplace" label="平均分名次" show-overflow-tooltip></el-table-column>
				<el-table-column prop="plansadd" label="计划数增加" show-overflow-tooltip></el-table-column>
			</el-table>
			<Pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
				@pagination="handleCurrentChange" />
			<div class="majorBox">
				<transition name="drawer">
					<div class="drawer" v-show="drawerShow">
						<div class="top">
							<h4>已选院校列表</h4>
							<el-button type="primary" size="mini">一键排序</el-button>
						</div>
						<el-table :data="uniSelect" border style="width: calc(100% - 30px)"
							:row-style="{ height: '30px' }" height="calc(100% - 93px)" @row-dblclick="innerRowClick">
							<el-table-column type="expand">
								<template slot-scope="scope">
									<div class="tagBox">
										<draggable v-model="scope.row.tempTaglist" chosenClass="chosen"
											forceFallback="true" animation="300" @start="onStart" @end="onEnd">
											<el-tag closable @close="handleClose(index, scope.row)" size="mini"
												effect="light" v-for="(item, index) in scope.row.tempTaglist"
												:key="index">
												{{ item.subjectName }}
											</el-tag>
										</draggable>
									</div>
								</template>
							</el-table-column>
							<el-table-column width="50px">
								<template slot-scope="scope">
									<el-button type="danger" icon="el-icon-delete" circle
										@click="handleDelete(scope.$index, scope.row)" size="mini">
									</el-button>
								</template>
							</el-table-column>
							<el-table-column min-width="80px" label="年份">
								<template slot-scope="scope">
									<span>{{
                    getGategoryName(scope.row.syS_Years, "SYS_Years")
                  }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="schoolName" label="学校名称" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="schoolCode" label="学校编号" show-overflow-tooltip></el-table-column>
							<el-table-column min-width="80px" label="文理科">
								<template slot-scope="scope">
									<span>{{
                    getGategoryName(
                      scope.row.syS_SubjectGroup,
                      "SYS_SubjectGroup"
                    )
                  }}</span>
								</template>
							</el-table-column>
							<el-table-column min-width="80px" label="批次">
								<template slot-scope="scope">
									<span>{{
                    getGategoryName(scope.row.syS_Times, "SYS_Times")
                  }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="plans" label="计划数" show-overflow-tooltip></el-table-column>
							<el-table-column prop="posts" label="投档数" show-overflow-tooltip></el-table-column>
							<el-table-column prop="receives" label="录取数" show-overflow-tooltip></el-table-column>
							<el-table-column prop="scores" label="分数详情" show-overflow-tooltip></el-table-column>
							<el-table-column prop="minscore" label="最低分" show-overflow-tooltip></el-table-column>
							<el-table-column prop="avescore" label="平均分" show-overflow-tooltip></el-table-column>
							<el-table-column prop="scoreline" label="分数线" show-overflow-tooltip></el-table-column>
							<el-table-column prop="minscoredif" label="最低分线差" show-overflow-tooltip></el-table-column>
							<el-table-column prop="avescoredif" label="平均分线差" show-overflow-tooltip></el-table-column>
							<el-table-column prop="minplace" label="最低分名次" show-overflow-tooltip></el-table-column>
							<el-table-column prop="scorelineplace" label="分数线名次" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="aveplace" label="平均分名次" show-overflow-tooltip></el-table-column>
							<el-table-column prop="plansadd" label="计划数增加" show-overflow-tooltip></el-table-column>
						</el-table>
					</div>
				</transition>
				<i class="el-icon-arrow-left iconLeft" @click="drawerShow = true" v-if="!drawerShow"></i>
				<i class="el-icon-arrow-right iconRight" @click="drawerShow = false" v-else></i>
			</div>
			<div class="btns">
				<el-button size="small" @click="$router.back('/home')">返回</el-button>
				<el-button size="small">加载更多</el-button>
				<!-- <el-button size="small">移出失效数据</el-button> -->
				<el-button size="small" @click="allClear()">清空所选</el-button>
				<!-- <el-button size="small">预览</el-button> -->
				<el-button size="small" @click="designShow = true">方案设计思路</el-button>
				<el-button size="small" @click="handlercommit" v-if="checkSchemed == false">暂存</el-button>
				<el-button size="small" @click="updatecommit" v-else>修改</el-button>
			</div>
		</div>
		<ChoiceMajor :choiceSchool="temp" :choiceShow="choiceShow" @closeChoic="closeChoic" @recList="recList"
			@clearSubject="clearSubject" v-show="choiceShow">
		</ChoiceMajor>
		<SearchHistory :school="temp" :historyShow="historyShow" @closeHistory="closeHistory"></SearchHistory>
		<DesignIdeas :designShow="designShow" @closeDesign="closeDesign"></DesignIdeas>
		<UniInfo :dialogShow="uniInfoShow" @closeUniinfo="closeUniinfo" :uniInfo="temp" :charOptions="charOptions">
		</UniInfo>
	</div>
</template>

<script>
	import draggable from 'vuedraggable'
	import UniInfo from "./uniInfo.vue";
	import * as schoolSubjectGroups from "@/api/schoolsubjectgroups";
	import * as categorys from "@/api/categorys";
	import Pagination from "@/components/Pagination";
	import ChoiceMajor from "./choiceMajor.vue";
	import SearchHistory from "./searchHistory.vue";
	import * as volunteers from "@/api/volunteers";
	import DesignIdeas from "../../components/designIdeas.vue";
	import AuthSelect from "@/components/AuthSelect";
	import {
		cityData
	} from "../../components/cityData.js";
	export default {
		components: {
			ChoiceMajor,
			DesignIdeas,
			Pagination,
			AuthSelect,
			SearchHistory,
			UniInfo,
			draggable,
		},
		data() {
			return {
				uniInfoList: [],
				charOptions: [],
				uniInfoShow: false,
				checkSchemed: false,
				isSearch: false,
				listLoading: true,
				drawerShow: false,
				choiceShow: false,
				designShow: false,
				historyShow: false,
				choiceList: [],
				outchoiceList: [],
				// 为了确认当前打开的选择专业窗口索引
				currentIndex: -1,
				thisYear: "2021",
				total: 0,
				getSchemeId: "",
				uniSelect: [],
				listQuery: {
					// 查询条件
					page: 1,
					limit: 20,
					key: undefined,
					appId: undefined,
					schoolName: "", //学校名称
					syS_Years: "", // 年份
					schoolCode: "", // 学校编号
					syS_SubjectGroup: "", // 文理科
					syS_CreateType: "", //院校类别
					syS_Times: "", // 批次
					minPlans: "", // 计划数
					maxPlans: "", // 计划数
					minPosts: "", // 投档数
					maxPosts: "", // 投档数
					minReceives: "", // 录取数
					maxReceives: "", // 录取数
					minScores: "", // 分数详情
					maxScores: "", // 分数详情
					minscore: "", // 最低分
					avescore: "", // 平均分
					scoreline: "", // 分数线
					minscoredif: "", // 最低分线差
					avescoredif: "", // 平均分线差
					minplace: "", // 最低分名次
					scorelineplace: "", // 分数线名次
					aveplace: "", // 平均分名次
					plansadd: "", // 计划数增加
					schoolRanking: "", // SchoolRanking
					minplace1: "", // Minplace1
					minplace2: "", // Minplace2
					minplace3: "", // Minplace3
					aveplace1: "", // Aveplace1
					aveplace2: "", // Aveplace2
					aveplace3: "", // Aveplace3
					syS_AdmissoinRule: "", // SYS_AdmissoinRule
					plansPostsReceives1: "", // PlansPostsReceives1
					plansPostsReceives2: "", // PlansPostsReceives2
					plansPostsReceives3: "", // PlansPostsReceives3
					scoreline1: "", // Scoreline1
					scoreline2: "", // Scoreline2
					scoreline3: "", // Scoreline3
					minScore1: "", // MinScore1
					minScore2: "", // MinScore2
					minScore3: "", // MinScore3
					aveScore1: "", // AveScore1
					aveScore2: "", // AveScore2
					aveScore3: "", // AveScore3
					select: false,
					cityName: "", //城市
					diC_KeySchool: [], //院校层次
					province: "", //省份
					MobileTel: ""
				},
				list: [],
				getApi: {
					// 查询条件
					page: 1,
					limit: 20,
					key: undefined,
					appId: undefined,
					mobileTel: "", // 手机号
					planName: "", // 方案名称
					SYS_ServiceStep: "", //初选，估分，知分
				},
				gategoryList: [],
				temp: {
					schoolName: "", //学校名称
					syS_Years: "", // 年份
					schoolCode: "", // 学校编号
					syS_SubjectGroup: "", // 文理科
					syS_Times: "", // 批次
					plans: "", // 计划数
					posts: "", // 投档数
					receives: "", // 录取数
					scores: "", // 分数详情
					minscore: "", // 最低分
					avescore: "", // 平均分
					scoreline: "", // 分数线
					minscoredif: "", // 最低分线差
					avescoredif: "", // 平均分线差
					minplace: "", // 最低分名次
					scorelineplace: "", // 分数线名次
					aveplace: "", // 平均分名次
					plansadd: "", // 计划数增加
					schoolRanking: "", // SchoolRanking
					minplace1: "", // Minplace1
					minplace2: "", // Minplace2
					minplace3: "", // Minplace3
					aveplace1: "", // Aveplace1
					aveplace2: "", // Aveplace2
					aveplace3: "", // Aveplace3
					syS_AdmissoinRule: "", // SYS_AdmissoinRule
					plansPostsReceives1: "", // PlansPostsReceives1
					plansPostsReceives2: "", // PlansPostsReceives2
					plansPostsReceives3: "", // PlansPostsReceives3
					scoreline1: "", // Scoreline1
					scoreline2: "", // Scoreline2
					scoreline3: "", // Scoreline3
					minScore1: "", // MinScore1
					minScore2: "", // MinScore2
					minScore3: "", // MinScore3
					aveScore1: "", // AveScore1
					aveScore2: "", // AveScore2
					aveScore3: "", // AveScore3
					extendInfo: "", // 其他信息,防止最后加逗号，可以删除
					tempTaglist: [], //存储专业列表数据
					MobileTel: "",
					cityName: "", //城市
					diC_KeySchool: [], //院校层次
					province: "", //省份
					syS_CreateType: "", //院校类别

				},
				SubjectGroup: [{
						value: "1",
						label: "文科",
					},
					{
						value: "2",
						label: "理科",
					},
				],
				syS_Times: [{
						value: "16",
						label: "高职高专",
					},
					{
						value: "12",
						label: "本科第二批",
					},
				],
				getUpdateSubjectId: [],
				province: [],
				city: [],
			};
		},
		created() {
			this.getGategorys();
			//console.log('估分方案create');
			this.getSchemeId = "";
			this.getSchemeId = this.$route.query.rowid;
			this.getList();
			this.province = this.getAreaList("111111");
		},
		mounted() {
			this.province.map((item) => {
				let temp = [];
				temp = this.getAreaList(item.code);
				temp.map((tempItem) => {
					this.city.push(tempItem);
				});
			});
			//console.log(this.city);
		},
		methods: {
			//开始拖拽事件
			onStart() {
				this.drag = true;
			},
			//拖拽结束事件
			onEnd() {
				this.drag = false;
				this.$forceUpdate();
			},
			UniInfoshow(index) {
				//console.log(index);
				this.getDiclist();
				this.temp = this.list[index];
				this.uniInfoShow = true;
			},
			// 获取"院校特色"字典列表
			getDiclist() {
				let listQuery = {
					page: 1,
					limit: 9999,
					TypeId: "DIC_KeySchool",
				};
				categorys.getList(listQuery).then((res) => {
					this.charOptions = res.data.map((item) => {
						var o = {
							label: item.name,
							value: item.dtValue,
						};
						return o;
						//this.charOptions.push(o);
					});
					console.log(this.charOptions);
				});
			},
			closeUniinfo(val) {
				this.uniInfoShow = val;
			},
			getAreaList(code) {
				let temp = [];
				if (code == null) {
					return temp;
				}
				if (code.substr(3, 3) == "000") {
					//城市列表

					for (let i in cityData[code]) {
						let o = {
							code: i,
							city: cityData[code][i],
						};
						temp.push(o);
					}
					//console.log(this.city);
				} else if (code.substr(4, 2) == "00") {
					//县区列表

					for (let i in cityData[code]) {
						let o = {
							code: i,
							county: cityData[code][i],
						};
						temp.push(o);
					}
					//console.log(this.county);
				} else {
					//省份列表
					for (let key in cityData.province) {
						cityData.province[key].map((province) => {
							temp.push(province);
						});
					}
				}
				return temp;
			},

			// 通过地址码显示对应的名称
			getAreaName(val) {
				if (val == null) {
					return "";
				}
				if (val.substr(3, 3) == "000") {
					//省份名称
					for (let key in cityData.province) {
						//console.log(cityData.province[key]);
						for (let i = 0; i < cityData.province[key].length; i++) {
							if (cityData.province[key][i].code == val) {
								//console.log(cityData.province[key][i].address);
								return cityData.province[key][i].address;
							}
						}
					}
				} else if (val.substr(4, 2) == "00") {
					//城市名称
					let cityParentCode = val.substr(0, 3) + "000";
					for (let i in cityData[cityParentCode]) {
						if (i == val) {
							return cityData[cityParentCode][i];
						}
					}
				} else {
					let countyParentCode = val.substr(0, 4) + "00";
					for (let i in cityData[countyParentCode]) {
						if (i == val) {
							return cityData[countyParentCode][i];
						}
					}
				}
				//console.log(val);
				return "";
			},
			getCityCode(val) {
				let index = this.city.findIndex((item) => {
					return item.city.indexOf(val) !== -1;
				});
				console.log(index);
				if (index !== -1) {
					return this.city[index].code;
				}
			},
			// 当搜索条件框关闭时，判断是否有检索条件，如果没有则重新获取列表数据
			handlePopoverHide(index, index1) {
				if (index !== "" || index1 !== "") {
					if (this.listQuery[index] == "" || this.listQuery[index1] == "") {
						this.getList();
					}
				}
			},
			searchHistory(index) {
				this.temp = this.list[index];
				this.temp.MobileTel = JSON.parse(localStorage.getItem("stuInfo")).mobileTel;
				//console.log(this.temp);
				this.choiceShow = false;
				this.historyShow = true;
			},
			rowClick(row) {
				let index = this.list.findIndex((item) => {
					return item == row;
				});
				let uniselectIndex = this.uniSelect.findIndex((item) => {
					return item.id == row.id;
				});
				console.log(uniselectIndex);
				//console.log(row);
				if (
					(row.select == undefined || row.select == false) &&
					uniselectIndex < 0
				) {
					this.$refs.mainTable.toggleRowSelection(row, !this.list[index].select); //设置复选框状态
					this.list[index].select = !this.list[index].select;
					this.choiceMajor(index, false);
				} else if (row.select == true || uniselectIndex >= 0) {
					this.choiceMajor(index, true);
					//this.uniSelect.splice(index, 1);
				}
			},
			innerRowClick(row) {
				console.log(row);
				let index = this.uniSelect.findIndex((item) => {
					return item == row;
				});
				//this.currentIndex = index;
				this.temp = this.uniSelect[index];
				this.choiceShow = true;
				//console.log(this.choiceShow);
				//this.choiceMajor(index);
				//console.log(row);
			},
			handleCurrentChange(val) {
				this.listQuery.page = val.page;
				this.listQuery.limit = val.limit;
				this.getList();
			},
			selectionAdd(nv, ov) {
				//新增选中院校
				let temp = JSON.parse(JSON.stringify(ov));
				console.log(nv);
				console.log(ov);
				for (let i = 0; i < nv.length; i++) {
					let index = ov.findIndex((item) => {
						return item.schoolName == nv[i].schoolName;
					});
					if (index == -1) {
						temp.push(nv[i]);
					}
				}
				return temp;
			},
			selectionRemove(nv, ov) {
				//移除选中院校
				//let temp = JSON.parse(JSON.stringify(ov));
				//console.log(nv.length);
				for (let i = 0; i < nv.length; i++) {
					let index = ov.findIndex((item) => {
						return item.schoolName == nv[i].schoolName;
					});
					if (index !== -1) {
						//console.log(i, index)
						ov.splice(index, 1);
					}
				}
				return ov;
			},
			handleSelect(selection, row) {
				let index = selection.findIndex((item) => {
					return item.schoolName == row.schoolName;
				});
				if (index == -1) {
					index = this.uniSelect.findIndex((item) => {
						return item.schoolName == row.schoolName;
					});
					console.log("delete");
					this.uniSelect.splice(index, 1);
				} else {
					this.uniSelect = this.selectionAdd(selection, this.uniSelect);
				}
				// if (!this.isSearch) {
				// 	this.uniSelect = selection;
				// } else {

				// }
			},
			handleSelectAll(selection) {
				if (selection.length !== 0) {
					// console.log(temp);
					this.uniSelect = this.selectionAdd(selection, this.uniSelect);
				} else {
					this.uniSelect = this.selectionRemove(this.list, this.uniSelect);
				}
				// if (!this.isSearch) {
				// 	this.uniSelect = selection;
				// } else {

				// }
			},
			handleDelete(index) {
				//console.log(index);
				var num = 0;
				while (num < 20) {
					if (this.uniSelect[index].schoolCode == this.list[num].schoolCode) {
						break;
					}
					num += 1;
				}
				//console.log(this.outchoiceList);
				this.uniSelect.splice(index, 1);
				this.$refs.mainTable.toggleRowSelection(this.list[num], false); //设置复选框状态
				this.list[num].select = !this.list[num].select;
				//console.log(this.uniSelect);
			},
			choiceMajor(index, check) {
				//this.currentIndex = index;
				//console.log(this.list[index]);
				this.temp = this.list[index];
				if (check == false) {
					this.temp.tempTaglist = [];
					this.uniSelect.push(this.temp);
				}
				//console.log(this.temp);
				this.choiceShow = true;
			},
			closeChoic(val) {
				this.choiceShow = val;
				//console.log(this.choiceShow);
			},
			closeHistory(val) {
				this.historyShow = val;
			},
			clearSubject(val) {
				//console.log(val);
				let index = this.uniSelect.findIndex((item) => {
					return item.schoolCode == val;
				});
				this.uniSelect[index]["tempTaglist"] = [];
				//console.log(this.uniSelect);
			},
			closeDesign(val) {
				this.designShow = val;
			},
			recList(val) {
				// console.log(val);
				let index = this.uniSelect.findIndex((item) => {
					//console.log(item.schoolCode,val[0].schoolCode);
					return item.schoolCode == val[0].schoolCode;
				});
				//console.log(index,this.uniSelect);
				this.uniSelect[index]["tempTaglist"] = val;
				console.log(this.uniSelect);
			},
			setClassName({
				row,
				index
			}) {
				// 通过自己的逻辑返回一个class或者空
				console.log(row);
				console.log(index);
				//return row.expand ? "expand" : "";
			},
			getList() {
				this.list = [];
				this.listLoading = true;
				this.listQuery.syS_Years = this.thisYear;
				// if (this.listQuery.city) {
				// 	this.listQuery.city = this.getCityCode(this.listQuery.city);
				// }
				if (
					JSON.parse(localStorage.getItem("stuInfo")).syS_SubjectGroup == "理科"
				) {
					this.listQuery.syS_SubjectGroup = "2";
				} else {
					this.listQuery.syS_SubjectGroup = "1";
				}
				//this.listQuery.syS_SubjectGroup = JSON.parse(localStorage.getItem("stuInfo")).syS_SubjectGroup;
				this.listQuery.MobileTel = JSON.parse(localStorage.getItem("stuInfo")).mobileTel;

				var listQuery = JSON.parse(JSON.stringify(this.listQuery));
				console.log(listQuery);
				schoolSubjectGroups.getList(this.listQuery).then((response) => {
					let list1 = response.data;
					console.log(list1);
					for (let i = 0; i < list1.length; i++) {
						var o = list1[i];
						if (o.diC_KeySchool) {
							o.diC_KeySchool = o.diC_KeySchool.split(",");
						}

						this.list.push(o);
					}
					// 重新获取列表数据时判断是否存在已选院校
					this.uniSelect.map((select) => {
						let index = this.list.findIndex((item) => {
							return select.schoolName == item.schoolName;
						});
						if (index !== -1) {
							this.list[index].select = true;
							this.$refs.mainTable.toggleRowSelection(this.list[index], true); //设置复选框状态
						}
					});
					//this.listLoading = true
					//schoolSubjectGroups.getList(this.listQuery).then(response => {
					//  this.list = response.data
					this.total = response.count;
					this.listLoading = false;
					//console.log(this.uniSelect);
					if (this.getSchemeId != null || this.getSchemeId != undefined) {
						this.checkSchemed = true;
						this.getUpdateList(this.getSchemeId);
					} else {
						this.checkSchemed = false;
					}
				});
			},
			getGategorys() {
				let listQuery = {
					page: 1,
					limit: 9999,
				};
				//console.log('getcategorys')
				categorys.getList(listQuery).then((res) => {
					this.gategoryList = res.data;
					//console.log(this.gategoryList);
					//for(let key in this.gategoryList){
					//    console.log(this.gategoryList[key])
					//}
					//console.log('getcategorys3')
				});
			},
			getGategoryName(val, columnName) {
				var dicobject = this.gategoryList.find(
					(t) =>
					t.typeId.toUpperCase() == columnName.toUpperCase() && t.dtValue == val
				);
				//var dicobject = this.gategoryList.find(t => {
				//  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) {
				//    return t
				//  }
				//});

				if (!dicobject) {
					return "";
				} else {
					return dicobject.name;
				}
			},
			resetSearcon() {
				this.listQuery = {
					page: 1,
					limit: 20,
					key: undefined,
					appId: undefined,
					schoolName: "", //学校名称
					syS_Years: "", // 年份
					schoolCode: "", // 学校编号
					syS_SubjectGroup: "", // 文理科
					syS_CreateType: "", //院校类别
					syS_Times: "", // 批次
					minPlans: "", // 计划数
					maxPlans: "", // 计划数
					minPosts: "", // 投档数
					maxPosts: "", // 投档数
					minReceives: "", // 录取数
					maxReceives: "", // 录取数
					minScores: "", // 分数详情
					maxScores: "", // 分数详情
					minscore: "", // 最低分
					avescore: "", // 平均分
					scoreline: "", // 分数线
					minscoredif: "", // 最低分线差
					avescoredif: "", // 平均分线差
					minplace: "", // 最低分名次
					scorelineplace: "", // 分数线名次
					aveplace: "", // 平均分名次
					plansadd: "", // 计划数增加
					schoolRanking: "", // SchoolRanking
					minplace1: "", // Minplace1
					minplace2: "", // Minplace2
					minplace3: "", // Minplace3
					aveplace1: "", // Aveplace1
					aveplace2: "", // Aveplace2
					aveplace3: "", // Aveplace3
					syS_AdmissoinRule: "", // SYS_AdmissoinRule
					plansPostsReceives1: "", // PlansPostsReceives1
					plansPostsReceives2: "", // PlansPostsReceives2
					plansPostsReceives3: "", // PlansPostsReceives3
					scoreline1: "", // Scoreline1
					scoreline2: "", // Scoreline2
					scoreline3: "", // Scoreline3
					minScore1: "", // MinScore1
					minScore2: "", // MinScore2
					minScore3: "", // MinScore3
					aveScore1: "", // AveScore1
					aveScore2: "", // AveScore2
					aveScore3: "", // AveScore3
					select: false,
					cityName: "", //城市
					diC_KeySchool: [], //院校层次
					province: "", //省份
					MobileTel: ""
				};
				this.uniSelect = [];
			},
			allClear() {
				this.resetSearcon();
				this.getList();
			},
			handlercommit() {
				var handlelist = [];
				//console.log(this.$store.state.stu.stuInfo);
				var allChoiceList = [];
				for (let i = 0; i < this.uniSelect.length; i++) {
					//console.log(this.uniSelect[i].tempTaglist);
					if (this.uniSelect[i].tempTaglist == undefined) {
						this.$message("请在【" + this.uniSelect[i].schoolName + "】选择专业");
						return;
					}
					var volunteerSchool = {
						schoolCode: this.uniSelect[i].schoolCode,
						SYS_Times: this.uniSelect[i].syS_Times,
						SYS_SubjectGroup: this.uniSelect[i].syS_SubjectGroup,
						volunteerSubjects: this.uniSelect[i].tempTaglist,
					};
					allChoiceList.push(volunteerSchool);
				}

				//console.log(allChoiceList);
				var mobileTel = JSON.parse(localStorage.getItem("stuInfo")).mobileTel;
				var data = {
					id: "",
					MobileTel: mobileTel,
					SYS_ServiceStep: 5,
					VolunteerSchools: allChoiceList,
				};
				handlelist.push(data);
				console.log(data);
				if (data.VolunteerSchools.length == 0) {
					return this.$message("请先选择学校以及专业!");
				}
				//提价保存
				volunteers.addNew(data).then((res) => {
					//this.list.unshift();
					if (res.code == 200) {
						//this.getList();
						this.$notify({
							title: "成功",
							message: "创建成功",
							type: "success",
							duration: 2000,
						});
						this.getNewSchemeId();
					} else {
						this.$message(res.message);
					}
				});
			},
			handleClose(val, indexrow) {
				//console.log(indexrow);
				//console.log(val);
				let indexs = this.uniSelect.findIndex((item) => {
					return item.id == indexrow.id;
				});
				//console.log(this.uniSelect[indexs].tempTaglist);
				this.uniSelect[indexs].tempTaglist.splice(val, 1);
				//console.log(this.uniSelect[indexs].tempTaglist);
			},
			getUpdateList(id) {
				volunteers.getVolunteers(id).then((res) => {
					console.log(res);
					let list1 = res.result;
					console.log(list1);
					let list2 = list1.volunteerSchools;
					for (let i = 0; i < list2.length; i++) {
						let voschool = JSON.parse(JSON.stringify(list2[i]));
						setTimeout(() => {
							this.getVolunteerList(voschool);
						}, 500);
					}
				});
			},
			getVolunteerList(val) {
				this.listLoading = true;
				this.listQuery.syS_Years = this.thisYear;
				this.listQuery.schoolCode = val.schoolCode;
				//console.log(this.listQuery);
				schoolSubjectGroups.getList(this.listQuery).then((response) => {
					let list1 = response.data;
					//console.log(list1);
					let index = list1.findIndex((item) => {
						return item.syS_SubjectGroup == val.syS_SubjectGroup;
					});
					let thelist = JSON.parse(JSON.stringify(val.volunteerSubjects));
					let getthesublist = thelist.map((item) => {
						return {
							id: item.id,
							schoolCode: item.schoolCode,
							subjectCode: item.subjectCode,
							subjectName: "测试",
						};
					});
					let listindex = this.list.findIndex((item) => {
						console.log(thelist[0].schoolCode);
						return item.schoolCode == thelist[0].schoolCode;
					});
					console.log(listindex);
					if (listindex !== -1) {
						this.list[listindex].select = true;
						this.$refs.mainTable.toggleRowSelection(this.list[listindex], true); //设置复选框状态
					}
					// var a = {
					//   id: "78a8f7be-3ba0-494f-8f16-b4f443e4fae2",
					//   schoolCode: "10292",
					//   subjectCode: "080207",
					//   subjectName: "测试",
					// };
					console.log(getthesublist);
					// for (let j = 0; j <val.volunteerSubjects.length; j++) {
					// 	let g = thelist[j];
					// 	//console.log(g);
					// 	testvolSubj.push(g);
					// 	var test = {};
					// 	test = {
					// 		id: g.id,
					// 		schoolCode: g.schoolCode,
					// 		subjectCode: g.subjectCode,
					// 		subjectName: "测试"
					// 	};
					// 	//console.log(test);
					// 	volSubj.push(test);
					// }
					//console.log(volSubj);
					//console.log(testvolSubj);
					list1[index].id = val.id;
					this.getUpdateSubjectId.push(val.id);
					list1[index]["tempTaglist"] = [];
					list1[index]["tempTaglist"] = getthesublist;
					this.uniSelect.push(list1[index]);
					console.log(list1[index]);
					this.listLoading = false;
				});
			},
			updatecommit() {
				var handlelist = [];
				//console.log(this.$store.state.stu.stuInfo);
				var allChoiceList = [];
				for (let i = 0; i < this.uniSelect.length; i++) {
					//console.log(this.uniSelect[i].tempTaglist);
					if (this.uniSelect[i].tempTaglist == undefined) {
						this.$message("请在【" + this.uniSelect[i].schoolName + "】选择专业");
						return;
					}
					let index = this.getUpdateSubjectId.findIndex((item) => {
						return item == this.uniSelect[i].id;
					});
					console.log(index);
					if (index == -1) {
						this.uniSelect[i].id = "";
					}
					var volunteerSchool = {
						id: this.uniSelect[i].id,
						schoolCode: this.uniSelect[i].schoolCode,
						SYS_Times: this.uniSelect[i].syS_Times,
						SYS_SubjectGroup: this.uniSelect[i].syS_SubjectGroup,
						volunteerSubjects: this.uniSelect[i].tempTaglist,
					};
					allChoiceList.push(volunteerSchool);
				}
				//console.log(allChoiceList);
				var mobileTel = JSON.parse(localStorage.getItem("stuInfo")).mobileTel;
				var data = {
					id: this.getSchemeId,
					MobileTel: mobileTel,
					SYS_ServiceStep: 5,
					VolunteerSchools: allChoiceList,
				};
				handlelist.push(data);
				console.log(data);
				if (data.VolunteerSchools.length == 0) {
					return this.$message("请先选择学校以及专业!");
				}
				//提价保存
				volunteers.updateNew(data).then((res) => {
					//this.list.unshift();
					if (res.code == 200) {
						//this.getList();
						this.getUpdateSubjectId = [];
						this.$notify({
							title: "成功",
							message: "修改成功",
							type: "success",
							duration: 2000,
						});
					} else {
						this.$message(res.message);
					}
				});
			},
			getNewSchemeId() {
				this.getApi = {
					limit: 99,
					page: 1,
				};
				this.getApi.mobileTel = JSON.parse(
					localStorage.getItem("stuInfo")
				).mobileTel;
				this.getApi.SYS_ServiceStep = "5";
				volunteers
					.getList(this.getApi)
					.then((res) => {
						let list1 = res.data;
						console.log(list1);
						this.getSchemeId = list1[list1.length - 1].id;
						console.log(this.getSchemeId);
					})
					.catch((res) => {
						console.log(res);
					});
			},
			sendVolunteers() {
				console.log(this.getSchemeId);
				if (this.getSchemeId == "" || this.getSchemeId == undefined) {
					return this.$message.error("发送操作有误,请先进行暂存或者修改!");
				} else {
					volunteers.send(this.getSchemeId).then((res) => {
						if (res.code == 200) {
							this.$notify({
								message: "发送成功",
								type: "success",
								duration: "2000",
								title: "成功",
							});
							this.getSchemeId = "";
							this.allClear();
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
		},
		computed: {
			oneYear() {
				return String(this.thisYear - 1);
			},
			twoYear() {
				return String(this.thisYear - 2);
			},
			threeYear() {
				return String(this.thisYear - 3);
			},
			// majorNum() {
			// 	let temp = 0;
			// 	for (let i = 0; i < this.uniSelect.length; i++) {
			// 		temp += this.uniSelect[i].choiceMajor.length;
			// 	}
			// 	return temp;
			// }
		},
	};
</script>

<style lang="scss" scoped="">
	.newScheme {
		background-color: white;
		margin: 15px;
		height: calc(100% - 30px);

		.top {
			display: flex;
			padding: 15px;
			border-bottom: 1px solid #eee;

			h3 {
				margin: 0;
			}

			.screen {
				color: #0076c8;
				cursor: pointer;
				margin-left: 10px;
			}

			.screen:hover {
				color: #0099ff;
			}

			.school {
				flex: 1;
				text-align: right;

				span {
					color: red;
					padding: 0 5px;
				}
			}

			.set {
				padding-left: 10px;
				color: #0076c8;
				cursor: pointer;
			}

			.set:hover {
				color: #0099ff;
			}

			// .filter-item {
			// 	position: fixed;
			// 	top: 105px;
			// 	right: 25px;
			// }
		}

		.container {
			padding: 15px;
			border: 1px solid #eee;
			height: calc(100% - 117px);

			p {
				margin: 0 0 10px 0;
			}

			.majorBox {

				.iconLeft,
				.iconRight {
					padding: 20px 5px;
					background-color: #0076c8;
					color: white;
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
					cursor: pointer;
					position: absolute;
					right: 0;
					top: 45%;
				}

				.iconRight {
					position: absolute;
					right: 70%;
				}

				.drawer-enter-active,
				.drawer-leave-active {
					transition: all 0.5s;
				}

				.drawer-enter,
				.drawer-leave-to

				/* .fade-leave-active below version 2.1.8 */
					{
					transform: translate(100%, 0);
				}

				.drawer {
					background-color: white;
					border: 1px solid #eee;
					position: absolute;
					right: 0;
					top: 70px;
					height: 83%;
					width: 70%;
					z-index: 99;

					.top {
						display: flex;
						padding: 15px;
						border-bottom: 1px solid #eee;

						h4 {
							margin: 0;
							font-weight: bold;
							flex: 1;
							line-height: 32px;
						}
					}

					.el-table {
						margin: 15px;

						.tagBox {
							height: 30px;

							// margin: 5px 10px;
							.el-tag {
								margin: 5px 10px;
								cursor: move;
							}

							.chosen {
								border: solid 1px #3089dc !important;
							}
						}
					}
				}
			}

			.btns {
				margin-top: 30px;
				text-align: right;
			}
		}
	}

	.el-popover {
		.selectBox {
			display: flex;
		}
	}
</style>
